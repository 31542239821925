<template>
  <div class="text-left LoginNew" style="overflow-x:hidden;">
    <div v-if="isLoading">
      <LoaderComp/>
    </div>
    <div v-else class="row h-100">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7 MainSection">
        <div class="">
          <!-- Logo -->
          <div class="px-5">
            <img
              src="/assets/img/new-website/LogoQuizell.svg"
              style="height: 40px; cursor: pointer"
              alt="Quizell"
              class="img-fluid"
              @click="$router.push('/')"
            />
          </div>

          <div class="TitleDiv">
            <h1 class="title">Log In to your account</h1>
          </div>

          <div>
             <LoaderComp/>
          </div>

          
        </div>
      </div>
      <div class="d-none d-lg-block col-lg-5 h-100 objectSection"></div>
    </div>
  </div>
</template>

<script>
import LoaderComp from "../customize/component/LoaderComp.vue";
export default {
  components:{
   LoaderComp 
  },
  data() {
    return {
      isLoading:false,
    };
  },
  methods: {
    
    async ShopifyLogIn(obj) {
      this.isLoading = true
        try {
          let data = obj
          fetch(`${process.env.VUE_APP_API_BASE_URL}shopify/login`, {
  method: "post",
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },

  //make sure to serialize your JSON body
  body: JSON.stringify(data) 
}).then( response => response.json())
.then( (response) => { 
     localStorage.setItem('user_access_token', response.access_token);
         this.$router.push('/manage/quizzes')
});

      
        } catch (error) {
           this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw error;
        } finally {
          this.isLoading = false
        }
     
    },
  },
  computed: {
   
  },
  created(){
    if(Object.entries( this.$route.query).length !== 0 ){
        if(this.$route.query){
            this.isLoading = true
            const obj = this.$route.query
           this.ShopifyLogIn(obj)
        }
   
      
    }
  }
};
</script>

<style scoped>
.LoginNew {
  height: 100vh;
}
.LoginNew .MainSection {
  padding: 2% 5%;
  background: #ffffff;
}
.MainSection .TitleDiv {
  padding: 48px;
}
.MainSection .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #161e34;
}
.objectSection {
  background-image: url("/assets/img/new-website/loginObject.png");
  background-color: #ffffff;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100vh;
}

.NewFormInput-Btn {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
}
.NewFormInput-Btn:hover {
  background: #ffc000;
  color: #ffffff;
}
.NewFormInput-Btn:disabled:hover {
  background: #4d1b7e !important;
}

.MainSection .register {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #161e34;
}
.MainSection .register button {
  color: #4d1b7e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media (max-width: 560px) {
  .MainSection .title {
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
  }

  .MainSection .TitleDiv {
    padding: 28px;
  }
}

.new-form-element p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    
    color: #161E34;
    }
    
    .NewFormInput {
        border: 1px solid #4D1B7E;
    border-radius: 10px;
        box-sizing: border-box;
        background:#ffffff;
    }
    
    .NewFormInput input {
        background: transparent;
        border: none;
        outline: none;
    }
    .NewFormInput input:focus {
        border: none;
        outline: none;
    }
    .NewFormInput input::placeholder {
       font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    text-align: start;
    
    color: #D1D1D1;
    }
    
    .NewFormInput-Btn{
        background: #4D1B7E;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    }
    .NewFormInput-Btn:hover{
        background: #FFC000;
    color: #FFFFFF;
    }
</style>
